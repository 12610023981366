<template>
  <section class="searchpage">
    <div v-if="loading">
      <div v-if="$route.params.category" class="title-page"></div>
    </div>
    <div v-if="!loading">
      <div
        class="headbanner title-banner"
        :style="{
          backgroundImage: `url(${
            allFilterJob.results.filter.category[0] == undefined
              ? img
              : 'https://app.necojobs.com.np' +
                allFilterJob.results.filter.category[0].bannerUrl
          })`,
        }"
        v-if="$route.params.category"
      >
        <v-container class="container-custom">
          <v-row>
            <v-col>
              <div class="banner">
                <div>
                  <h1>
                    {{ allFilterJob.results.filter.category[0].for }}
                  </h1>

                  <div
                    v-html="allFilterJob.results.filter.category[0].description"
                  ></div>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <div
      class="headbanner1"
      :style="{
        backgroundImage: `url(${require('@/assets/searchBanner.jpg')})`,
      }"
      v-if="!$route.params.category"
    ></div>
    <v-container class="container-custom" v-if="loading">
      <v-row>
        <v-col md="3">
          <div class="boxinside">
            <v-skeleton-loader
              max-width="100%"
              type="image"
              height="200"
            ></v-skeleton-loader>
            <v-skeleton-loader
              max-width="100%"
              type="image"
              height="200"
            ></v-skeleton-loader>
            <v-skeleton-loader
              max-width="100%"
              type="image"
              height="200"
            ></v-skeleton-loader>
            <v-skeleton-loader
              max-width="100%"
              type="image"
              height="200"
            ></v-skeleton-loader>
          </div>
        </v-col>
        <v-col md="9">
          <div class="boxinside">
            <div class="marginbtm">
              <v-skeleton-loader
                max-width="100%"
                type="image"
                height="200"
              ></v-skeleton-loader>
            </div>
            <div class="marginbtm">
              <v-skeleton-loader
                max-width="100%"
                type="image"
                height="200"
              ></v-skeleton-loader>
            </div>
            <div class="marginbtm">
              <v-skeleton-loader
                max-width="100%"
                type="image"
                height="200"
              ></v-skeleton-loader>
            </div>
            <div class="marginbtm">
              <v-skeleton-loader
                max-width="100%"
                type="image"
                height="200"
              ></v-skeleton-loader>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="container-custom filters" v-if="!loading">
      <v-row>
        <v-col md="3">
          <div class="filter-section" v-if="filter != undefined">
            <h3>Filter</h3>
            <v-form
              ref="filtersearch"
              v-model="valid"
              lazy-validation
              class="form-section"
            >
              <div class="mr-btm">
                <h4>Available For</h4>
                <ul class="filter-list" v-if="filter">
                  <li
                    v-for="(data, index) in filter.availabilty"
                    :key="index + 'a'"
                  >
                    <v-checkbox
                      v-model="data.value"
                      class="hidefield"
                      :label="data.for"
                    ></v-checkbox>
                  </li>
                </ul>
              </div>
              <div class="mr-btm category-btn category-filter">
                <h4>Category</h4>
                <ul class="filter-list" v-if="filter">
                  <li
                    v-for="(data, index) in filter.category"
                    :key="index + 'c'"
                  >
                    <v-checkbox
                      v-model="data.value"
                      class="hidefield"
                      :title="data.for"
                      :label="
                        data.for.length > 30
                          ? data.for.slice(0, 28) + '...'
                          : data.for
                      "
                    ></v-checkbox>
                  </li>
                </ul>
              </div>
              <div class="mr-btm category-filter">
                <h4>Company</h4>
                <ul class="filter-list" v-if="filter">
                  <li
                    v-for="(data, index) in filter.company"
                    :key="index + 'c'"
                  >
                    <v-checkbox
                      v-model="data.value"
                      class="hidefield"
                      :title="data.for"
                      :label="data.for"
                    ></v-checkbox>
                  </li>
                </ul>
              </div>
              <div class="mr-btm category-filter">
                <h4>Experience</h4>
                <ul class="filter-list" v-if="filter">
                  <li
                    v-for="(data, index) in filter.experienceLevel"
                    :key="index + 'e'"
                  >
                    <v-checkbox
                      v-model="data.value"
                      class="hidefield"
                      :title="data.for"
                      :label="data.for"
                    ></v-checkbox>
                  </li>
                </ul>
              </div>
              <div class="mr-btm category-filter">
                <h4>Location</h4>
                <ul class="filter-list" v-if="filter">
                  <li
                    v-for="(data, index) in filter.location"
                    :key="index + 'l'"
                  >
                    <v-checkbox
                      v-model="data.value"
                      class="hidefield"
                      :title="data.for"
                      :label="data.for"
                    ></v-checkbox>
                  </li>
                </ul>
              </div>
              <div class="fitler-btn">
                <div class="">
                  <v-btn class="btn-filter" @click="filterdata">Filter</v-btn>
                </div>
                <div class="">
                  <v-btn class="btn-filter" @click="resetdata">Reset</v-btn>
                </div>
              </div>
            </v-form>
          </div>
        </v-col>

        <v-col md="9" v-if="allFilterJob.results">
          <div class="nojobs mb-3" v-if="$route.params.slug">
            Search for : {{ $route.params.slug }}
          </div>
          <div
            class="boxinside pagination-front"
            v-if="allFilterJob.results.pagination.total > 0"
          >
            <div>
              Page :
              <span v-if="allFilterJob.results.pagination.next">{{
                (allFilterJob.results.pagination.next - 1) * this.limit
              }}</span
              ><span v-if="!allFilterJob.results.pagination.next">{{
                allFilterJob.results.pagination.total
              }}</span>
              -
              {{ allFilterJob.results.pagination.total }}
            </div>
            <ul>
              <div>
                <v-btn
                  v-if="allFilterJob.results.pagination.previous"
                  @click="prevPage(allFilterJob.results.pagination.previous)"
                  class="btn-dark"
                  >Prev</v-btn
                >

                <v-btn
                  v-if="allFilterJob.results.pagination.next"
                  @click="nextPage(allFilterJob.results.pagination.next)"
                  class="btn-dark"
                  >Next</v-btn
                >
              </div>
            </ul>
          </div>
          <div
            class="marginbtm"
            v-for="(data, index) in allFilterJob.results.jobs"
            :key="index"
          >
            <SearchboxPage :job="data" />
          </div>
          <div v-if="allFilterJob.results.jobs.length == 0" class="nojobs">
            <h1>No Jobs Found</h1>
          </div>

          <div
            class="boxinside pagination-front pagination-bottom"
            v-if="allFilterJob.results.pagination.total > 0"
          >
            <div>
              Page :
              <span v-if="allFilterJob.results.pagination.next">{{
                (allFilterJob.results.pagination.next - 1) * this.limit
              }}</span
              ><span v-if="!allFilterJob.results.pagination.next">{{
                allFilterJob.results.pagination.total
              }}</span>
              -
              {{ allFilterJob.results.pagination.total }}
            </div>
            <ul>
              <div>
                <v-btn
                  v-if="allFilterJob.results.pagination.previous"
                  @click="prevPage(allFilterJob.results.pagination.previous)"
                  class="btn-dark"
                  >Prev</v-btn
                >

                <v-btn
                  v-if="allFilterJob.results.pagination.next"
                  @click="nextPage(allFilterJob.results.pagination.next)"
                  class="btn-dark"
                  >Next</v-btn
                >
              </div>
            </ul>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
// import Banner from "@/components/frontend/Banner";
import SearchboxPage from "@/components/frontend/SearchboxPage";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "SearchJob",
  components: {
    // Banner,
    SearchboxPage,
  },
  data: () => ({
    bannerImage: require("@/assets/searchBG.jpg"),
    img: require("@/assets/pattern.jpg"),
    checkbox: false,
    valid: true,
    skip: 1,
    limit: 5,
    filter: {},
    selectedCategories: [],
    loading: true,
  }),
  //testing
  methods: {
    ...mapActions([
      "filterJob",
      "getAssets",
      "getCategoriesHome",
      "getSingleMetaPage",
    ]),
    resetdata() {
      const isCategorySlugPage = !!this.$route.params.category;

      // Reset all filter values to false
      this.filter.availabilty.forEach((ele) => (ele.value = false));
      this.filter.company.forEach((ele) => (ele.value = false));
      this.filter.experienceLevel.forEach((ele) => (ele.value = false));
      this.filter.location.forEach((ele) => (ele.value = false));

      if (!isCategorySlugPage) {
        this.filter.category.forEach((ele) => (ele.value = false));
      }

      let filter = [];

      if (isCategorySlugPage) {
        filter.push({
          categorySlug: {
            $regex: this.$route.params.category,
            $options: "i",
          },
        });
      }

      this.filterJob({
        filter: JSON.stringify(filter),
        skip: this.skip,
        limit: this.limit,
      });
      this.$router.push("/search");
    },

    filterdata() {
      let filterdata = [];

      this.filter.experienceLevel.forEach((ele) => {
        if (ele.value) {
          filterdata.push({ jobLevel: ele.for });
        }
      });
      this.filter.availabilty.forEach((ele) => {
        if (ele.value) {
          filterdata.push({ availableFor: ele.for });
        }
      });
      this.filter.company.forEach((ele) => {
        if (ele.value) {
          filterdata.push({ companyName: ele.for });
        }
      });
      this.filter.location.forEach((ele) => {
        if (ele.value) {
          filterdata.push({ jobLocation: [{ location: ele.for }] });
        }
      });
      if (this.$route.params.slug) {
        filterdata.push({
          jobPosition: { $regex: this.$route.params.slug, $options: "i" },
        });
      }

      if (this.$route.params.category) {
        filterdata.push({
          categorySlug: {
            $regex: this.$route.params.category,
            $options: "i",
          },
        });
      }

      this.filter.category.forEach((ele) => {
        if (ele.value) {
          filterdata.push({ category: ele.for });
        }
      });

      this.filterJob({
        filter: JSON.stringify(filterdata),
        skip: this.skip,
        limit: this.limit,
      });
    },
    nextPage(data) {
      this.skip = data;
      this.filterdata();
    },
    prevPage(data) {
      this.skip = data;
      this.filterdata();
    },
  },

  computed: {
    ...mapGetters([
      "allFilterJob",
      "allAssets",
      "allHomeCategoriesJob",
      "allMeta",
    ]),
  },
  metaInfo() {
    return {
      title:
        this.allMeta && this.$route.params.category == undefined
          ? this.allMeta.title
          : this.allFilterJob.results && this.allFilterJob.results.filter
          ? this.allFilterJob.results.filter.category[0]
            ? this.allFilterJob.results.filter.category[0].metaTitle
            : "Necojobs / Nepal's #1 job portal"
          : "Necojobs / Nepal's #1 job portal",
      meta: [
        {
          name: "description",
          content:
            this.allMeta && this.$route.params.category == undefined
              ? this.allMeta.metaDescription
              : this.allFilterJob.results && this.allFilterJob.results.filter
              ? this.allFilterJob.results.filter.category[0]
                ? this.allFilterJob.results.filter.category[0].metaDescription
                : "Necojobs / Nepal's #1 job portal"
              : "Necojobs / Nepal's #1 job portal",
        },
        {
          name: "keywords",
          content:
            this.allMeta && this.$route.params.category == undefined
              ? this.allMeta.metaKeywords
              : this.allFilterJob.results && this.allFilterJob.results.filter
              ? this.allFilterJob.results.filter.category[0]
                ? this.allFilterJob.results.filter.category[0].metaKeywords
                : "Necojobs / Nepal's #1 job portal"
              : "Necojobs / Nepal's #1 job portal",
        },
      ],

      link: [
        {
          rel: "canonical",
          href: `https://www.necojobs.com.np/category/${this.$route.params.category}`,
        },
      ],
    };
  },
  async created() {
    await this.getSingleMetaPage("find-jobs");
    this.selectedCategories = [];
    if (this.$route.params.slug != undefined) {
      await this.filterJob({
        filter: JSON.stringify({
          jobPosition: { $regex: this.$route.params.slug, $options: "i" },
          status: true,
        }),
        skip: this.skip,
        limit: this.limit,
      });
      this.filter = this.allFilterJob.results.filter;
    } else if (this.$route.params.category != undefined) {
      await this.filterJob({
        filter: JSON.stringify({
          categorySlug: { $regex: this.$route.params.category, $options: "i" },
          status: true,
        }),
        skip: this.skip,
        limit: this.limit,
      });
      this.filter = this.allFilterJob.results.filter;
    } else {
      await this.filterJob({
        filter: {},
        skip: this.skip,
        limit: this.limit,
      });
      this.filter = this.allFilterJob.results.filter;
    }

    await this.getAssets({
      title: "SearchBanner",
      connectedTo: "page",
    });
    if (this.allAssets.length > 0) this.bannerUrl = this.allAssets[0].image;

    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.searchpage {
  background: #f8f8f8;
  overflow: hidden !important;
}
.margin-minus-top {
  position: relative;
  top: -80px;
}
.headerjob {
  background: linear-gradient(90deg, #306fd3 0, #2f6fd3 60%, #5d8fd9);
  min-height: 150px;
}
h1 {
  font-size: 20px;
  font-weight: 500;
}
h2 {
  font-size: 15px;
  font-weight: 500;
}
.spacebtm {
  margin-bottom: 20px;
}
h3 {
  font-size: $iconfont;
  font-weight: 600;
  padding: 10px;
  margin-bottom: 15px;
  background-color: hsl(0, 0%, 92%);
  border-radius: 5px;
}
.requirementbox {
  display: flex;
  flex-flow: column;
  strong {
    font-weight: 400;
    font-size: $midfont;
    color: $tagcolor;
  }
  span {
    font-size: $normalfont;
  }
}
.flexbox-center {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-flow: column;
  &-left {
    align-items: flex-start;
  }
  &-right {
    align-items: flex-end;
  }
}
.boxinside {
  box-shadow: 0 1px 1px 0 rgb(66 66 66 / 8%), 0 1px 3px 1px rgb(66 66 66 / 16%);
  transition: width 235ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
  padding: 20px;
  background: $white;
  background: $white;
  margin-bottom: 20px;
  &:last-of-type {
    margin-bottom: 0;
  }
}
.logobox {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  img {
    width: 150px;
  }
}
.aboutcontent {
  font-size: $midfont;
  margin-top: 10px;
}
.actionbutton {
  display: flex;
  justify-content: center;
  a {
    text-decoration: none;
  }
  .v-icon {
    font-size: $iconfont;
  }
  .socialshare {
    box-shadow: 0px 1px 14px 1px rgba(222, 222, 222, 1);
    padding: 10px;
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
  }
  li {
    margin-left: 0;
    margin-right: 15px;
    position: relative;
    cursor: pointer;
    &:hover {
      .socialshare {
        display: flex;
        background: $white;
        flex-flow: row;
      }
    }
  }
}

.btn-actions {
  font-size: $normalfont;
  margin-top: 10px;
  li {
    margin-left: 0;
    padding: 10px;
    background: red;
    padding: 5px 20px;
    font-size: $midfont;
    cursor: pointer;
    border-radius: 25px;
    color: $white;
    &:first-of-type {
      background: $tagcolor;
    }
    &:nth-of-type(2) {
      background: $greentva;
    }
    &:nth-of-type(3) {
      background: $white;
      border: 1px solid $tagcolor;
      color: $tagcolor;
    }
  }
}
small {
  a {
    text-decoration: none;
    color: $tagcolor;
    .v-icon {
      font-size: $iconfont;
      text-decoration: none;
    }
  }
}
.category-btn {
  margin-top: -100px;
}
.filter-list::-webkit-scrollbar {
  display: none;
}
.filter-list {
  flex-flow: column;
  display: flex;
  height: 130px;
  overflow-y: scroll;
  padding: 10px !important;

  li {
    margin-left: 0;
    padding: 0;
  }
}
h4 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
  padding-bottom: 0;
}
.mr-btm {
  margin-bottom: 15px;
  &:last-of-type {
    margin-bottom: 0;
  }
  h4 {
    border-bottom: 1px solid rgb(224, 224, 224);
    padding: 10px;
  }
}
.marginbtm {
  margin-bottom: 20px;
}
.container-custom {
  padding: 50px 0;
}
.btn-filter {
  background: $tagcolor !important;
  width: 110px;
  color: $white;
  margin-top: 20px;
}
ul {
  list-style-type: none;
  padding: 0;

  li {
    display: inline-block;
    margin: 0 10px;
  }
}

.headbanner {
  background-size: contain;
  height: 245px;
  width: 100%;
  background-color: #f6fdff;
  background-position: center center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-repeat: repeat-x;
}
.banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 30%;
  }
  h1 {
    font-size: 35px;
    font-weight: bold;
  }
  p {
    display: flex;
    color: rgb(75, 75, 75);
    flex-direction: column;
  }
}
@media screen and (max-width: 992px) {
  .headbanner {
    background-size: cover;
    height: 120px;
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .pagination-bottom {
    margin-bottom: 20px !important;
  }
  .title-banner {
    height: auto !important;
    width: 1000%;
    overflow: hidden;
  }
  .banner {
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    h1 {
      font-size: 27px;
    }
    p {
      span {
        font-size: 13px;
      }
    }
    img {
      width: 100%;
    }
  }
  .headbanner {
    height: 32vh;
    width: 100%;
  }
  .filters {
    padding: 0 10px;
    margin-top: 40px;
    overflow: hidden;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .headbanner {
    height: 30vh;
    width: 100%;
    background-position: center;
  }
  .filters {
    width: 100%;
    padding: 0 20px;
    margin: 40px 0px;
  }
  .searchpage {
    overflow: hidden !important;
  }
}
.fitler-btn {
  display: flex !important;
  justify-content: center !important;
  gap: 10px;
}
.btn-dark {
  margin-left: 10px;
  background: $tagcolor !important;
  color: $white;
}
.filter-list {
  flex-flow: column;
  display: flex;
  overflow-y: auto;
  max-height: 200px;
  &::-webkit-scrollbar {
    display: none;
  }

  li {
    margin-left: 0;
    padding: 0;
  }
}

.category-filter {
  .filter-list {
    max-height: none;
    height: auto;
  }
}

@media screen and (min-height: 200px) {
  .category-filter {
    .filter-list {
      max-height: 133px;
      height: auto;
    }
  }
}
.nojobs {
  background: $white !important;
  box-shadow: 0px 1px 4px 1px rgba(222, 222, 222, 1);
  padding: 10px 20px;
}
.title-page {
  width: 100%;
  height: 257px;
  background: #ebebeba8;
}
.filter-section {
  border-radius: 10px;
  background: white;
  padding: 15px 15px 2px 15px;
  box-shadow: 0 1px 1px 0 rgb(66 66 66 / 8%), 0 1px 3px 1px rgb(66 66 66 / 16%);
  transition: width 235ms 0ms cubic-bezier(0.4, 0, 0.2, 1);
}
.pagination-front {
  border-radius: 10px;
}
.headbanner1 {
  height: 245px !important;
  width: 100% !important;
  background-position: center;
  background-size: cover;
}
</style>
