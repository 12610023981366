<template>
  <section>
    <div>
      <v-row>
        <v-col md="2" class="logobox">
          <router-link :to="`/singeCompany/${job.companySlug}`"
            ><img
              :src="`https://app.necojobs.com.np${job.companyImage}`"
              alt=""
          /></router-link>
        </v-col>
        <v-col md="10">
          <ul class="jobdetail">
            <li class="jobtitle">
              <router-link :to="`/singleJob/${job.slug}`">{{
                job.jobPosition
              }}</router-link>
            </li>
            <li class="companyName">
              <router-link :to="`/singeCompany/${job.companySlug}`">{{
                job.companyName
              }}</router-link>
            </li>
            <li class="company-info">
              <span v-for="(data, index) in job.jobLocation" :key="index"
                ><v-icon> mdi-map-marker </v-icon>{{ data.location }}</span
              >
              <span class="ta">
                {{ job.jobLevel }}
              </span>
              <span class="ta">
                {{ job.availableFor }}
              </span>
            </li>
          </ul>
        </v-col>
      </v-row>
    </div>

    <footer>
      <ul class="actionbutton">
        <li>
          Share
          <v-icon> mdi-share-variant </v-icon>
          <ul class="socialshare">
            <li>
              <ShareNetwork
                network="facebook"
                :url="`https://www.necojobs.com.np/singleJob/${job.slug}`"
                :title="job.jobPosition"
                quote="A better job"
                hashtags="necojobs,necojobs job"
              >
                <v-icon> mdi-facebook </v-icon>
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
                network="whatsapp"
                :url="`https://www.necojobs.com.np/singleJob/${job.slug}`"
                :title="job.jobPosition"
              >
                <v-icon> mdi-whatsapp </v-icon>
              </ShareNetwork>
            </li>
            <li>
              <ShareNetwork
                network="linkedin"
                :url="`https://www.necojobs.com.np/singleJob/${job.slug}`"
                :title="job.jobPosition"
              >
                <v-icon> mdi-linkedin </v-icon>
              </ShareNetwork>
            </li>
          </ul>
        </li>
        <li>
          <ul class="nomargin">
            <!-- <li>View : 0</li> -->
            <li>Published On : {{ job.jobStartDate | formatDate }}</li>
          </ul>
        </li>
      </ul>
    </footer>
  </section>
</template>

<script>
export default {
  name: "SearchboxPage",
  props: ["status", "job", "companyname", "companylogo"],
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
section {
  background: $white !important;
  box-shadow: 0px 1px 4px 1px rgba(222, 222, 222, 1);
  padding: 10px 20px;
  border-radius: 10px;
  div.row {
    padding: 20px 0;
  }
}
.status {
  background: $tagcolor;
  display: block;
  width: 120px;
  text-align: center;
  padding: 2px;
  margin-top: 5px;
  border-radius: 30px;
  color: $white;
  font-size: $normalfont;
}
.actionbutton {
  display: flex;
  justify-content: space-between;
  background: $white;
  a {
    text-decoration: none;
  }
  .v-icon {
    font-size: $iconfont;
    margin-left: 10px;
  }
  .socialshare {
    box-shadow: 0px 1px 14px 1px rgba(222, 222, 222, 1);
    padding: 10px;
    display: none;
    position: absolute;
    left: 0;
    top: 100%;
  }
  li {
    margin-left: 0;
    margin-right: 15px;
    position: relative;
    cursor: pointer;
    &:hover {
      .socialshare {
        display: flex;
        background: $white;
        flex-flow: row;
      }
    }
  }
}
.nomargin {
  li {
    margin-right: 0;
    margin-left: 15px;
    font-size: 14px;
  }
}
footer {
  border-top: 1px solid #ebe8e8;
  padding: 10px 0 0;
}
.logobox {
  // padding: 10px;
  img {
    width: 120px;
  }
  // box-shadow: 0px 1px 14px 1px rgba(222, 222, 222, 0.8);
  border: 1px solid rgb(194, 194, 194);
  display: flex;
  align-items: center;
  justify-content: center;
}
.jobdetail {
  display: flex;
  margin: 0;
  flex-flow: column;
  li {
    margin-bottom: 5px;
    a {
      color: $tagcolor;
      text-decoration: none;
      font-weight: 500;
    }
  }
  .jobtitle {
    a {
      font-size: $iconfont;
    }
  }
  .companyName {
    margin-bottom: 10px;
    a {
      font-size: $normal_lfont;
    }
  }
  .location {
    font-size: $normalfont;
    .v-icon {
      font-size: $normal_lfont;
    }
  }
}
ul {
  list-style-type: none;
  padding: 0;

  li {
    display: inline-block;
    margin: 0 10px;
  }
}
.ta {
  text-align: center;
}
.company-info {
  display: flex;
  align-items: center;
  font-size: $normalfont;
  .v-icon {
    font-size: $normal_lfont;
  }
}

.company-info > span:nth-child(1) {
  padding-right: 8px;
  border-right: 2px solid rgb(222, 219, 219);
}

.company-info > span:nth-child(2) {
  padding: 0 8px 0 8px;
  border-right: 2px solid rgb(222, 219, 219);
}

.company-info > span:nth-child(3) {
  padding: 0 8px 0 8px;
}
@media screen and (max-width: 600px) {
  .company-info {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 5px;
    font-size: 12px !important;
    font-size: $normalfont;
    .v-icon {
      font-size: $normal_lfont;
    }
  }
  .company-info > span:nth-child(1) {
    padding-right: 0;
    border: none;
    text-align: left !important;
  }

  .company-info > span:nth-child(2) {
    border: none;
    text-align: left !important;
  }

  .company-info > span:nth-child(3) {
    border: none;
    text-align: left !important;
  }
}
</style>
